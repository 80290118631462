<template>
	<div class="patients">
		<div class="bg" v-hammer:tap="()=> close_patients()"></div>
		<div class="content">
			<div v-if="true" class="title">{{ $t('archives') }}</div>
			<div v-else class="title">{{ $t('patient_selection') }}</div>
			<div class="pages">
				<div class="page page-patients" :class="{ active: active_tab == 'patients' }" :style="{ zIndex: tabs.patients.zIndex }">
					<div class="tab" v-hammer:tap="()=> set_active_tab('patients')">{{ $t('patients') }}</div>
					<div class="page-content">
						<div class="patients-scroll" v-scrollbar v-if="totalNbGames">
							<div>
								<tab-patients
									@set_active_tab="set_active_tab"
									:scopes="scopes"
									:totalNbGames="totalNbGames"
									@information-summary="informationSummary"
								/>
							</div>
						</div>
						<div class="filter-tabs" v-if="totalNbGames">
							<div class="filter-tab" :class="{ active: patients.filters.accredited_game_equal }" v-hammer:tap="()=> toggle_accredited(true)">{{ $t('accredited') }}</div>
							<div class="filter-tab" :class="{ active: !patients.filters.accredited_game_equal }" v-hammer:tap="()=> toggle_accredited(false)">{{ $t('unaccredited') }}</div>
						</div>
						<div v-show="app.loading" class="loading"></div>
					</div>
				</div>
				<div class="page page-filters" :class="{ active: active_tab == 'filters' }" :style="{ zIndex: tabs.filters.zIndex }">
					<div class="tab" v-hammer:tap="()=> set_active_tab('filters')">{{ $t('filters') }}</div>
					<div class="page-content">
						<tab-filters
							@set_active_tab="set_active_tab"
						/>
					</div>
				</div>
				<div class="page page-search" :class="{ active: active_tab == 'search' }" :style="{ zIndex: tabs.search.zIndex }">
					<div class="tab" v-hammer:tap="()=> set_active_tab('search')">{{ $t('search') }}</div>
					<div class="page-content">
						<tab-search
							@set_active_tab="set_active_tab"
						/>
					</div>
				</div>
				<div class="close" v-hammer:tap="()=> close_patients()"></div>
			</div>
		</div>

		<div v-if="displayInformationSummary" class="dark-back notepad">
			<transition name="notepad" appear>
				<notepad
					:pages="pages"
					type="buttons"
					end-action="close"
					@information-summary="informationSummary"
				/>
			</transition>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabPatients from '@/components/patients/tab-patients'
import TabFilters from '@/components/patients/tab-filters'
import TabSearch from '@/components/patients/tab-search'
import Notepad from '@/components/game/notepad'
import Feature from 'feature.js'
import Apollo from "@/apollo/apollo-client"
import Vue from 'vue'

export default {
	name: 'patients',
	props: [ 'scopes', 'nbGames' ],
	components: { Notepad, TabPatients, TabFilters, TabSearch },
	data() {
		return {
			displayInformationSummary: false,
			informationSummaryData: null,
			totalNbGames: null,
			active_tab: false,
			tabs: {
				patients: { zIndex: 3 },
				filters: { zIndex: 2 },
				search: { zIndex: 1 }
			},
			filter_tab: 'accredited'
		}
	},
	computed: {
		...mapGetters([ 'app', 'patients', 'gameFilters' ]),
		pages() {
			if (!this.displayInformationSummary) return false
			return (this.informationSummaryData || [])
				.map(({ html:text }) => ({
					title: Vue.i18n.translate('information_summary'),
					text
				}))
		},
	},
	watch: {
		'patients.selected_game'(newValue, oldValue) {
			if (newValue) {
				let patients_scroll = this.$refs.patients_scroll
				if (patients_scroll) {
					if (Feature.touch) patients_scroll = patients_scroll.parentElement
					let el = patients_scroll.querySelector('#patient-file-' + newValue.id)
					if (el) {
						this.$nextTick(() => {
							this.scrollTo(patients_scroll, el.offsetTop - 76, 150)
						})
					}
				}
			}
		}
	},
	methods: {
		informationSummary(data) {
			this.informationSummaryData = data
			this.displayInformationSummary = !!data
		},
		close_patients() {
			this.$store.dispatch('set_patients_visible', { patients_visible: false })
		},
		set_active_tab(tab) {
			if (!this.active_tab && tab === 'patients') return
			this.active_tab = tab
			setTimeout(() => {
				let max = Object.keys(this.tabs).reduce((a, key) => this.tabs[key].zIndex > a ? this.tabs[key].zIndex : a, 0)
				this.tabs[tab].zIndex = max + 1
			}, 125)
			if (tab === 'patients') {
				const { scopes } = this.$props
				this.$store.dispatch('get_games_filtered', scopes ? { scopes } : {})
			}
		},
		async toggle_accredited(value) {
			this.$store.commit('SET_PATIENTS_FILTER_PROPERTY', { property: 'accredited_game_equal', value })
			await this.$store.dispatch('set_game_filters_with', {
				values: [ this.$store.getters.patients.filters, this.$store.getters.patients.search ], needed: [ this.$store.getters.patients.neededFilters, this.$store.getters.patients.neededSearch ]
			})
			const { scopes } = this.$props
			await this.$store.dispatch('get_games_filtered', scopes ? { scopes } : {})

			this.totalNbGames = await this.getTotalNbGames()
		},
		async getTotalNbGames() {
			const { patients } = this
			const value = patients?.filters?.accredited_game_equal || false
			this.$store.commit('SET_PATIENTS_FILTER_PROPERTY', { property: 'accredited_game_equal', value })
			this.$store.commit('SET_LOADING', { loading: true })
			const result = (await Apollo.query('CLIENT_GAME_FILTERED', { filters: this.gameFilters.with }))
				.data.length
			this.$store.commit('SET_LOADING', { loading: false })
			return result
		}
	},
	async mounted() {
		const { patients } = this
		const value = patients?.filters?.accredited_game_equal || false
		this.$store.commit('SET_PATIENTS_FILTER_PROPERTY', { property: 'accredited_game_equal', value })
		await this.$store.dispatch('set_game_filters_with', {
			values: [ this.$store.getters.patients.filters, this.$store.getters.patients.search ], needed: [ this.$store.getters.patients.neededFilters, this.$store.getters.patients.neededSearch ]
		})
		const { scopes } = this.$props
		await this.$store.dispatch('get_games_filtered', scopes ? { scopes } : {})
		this.totalNbGames = await this.getTotalNbGames()
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.patients
		.content
			max-height 700px
			height calc(100% - 24px)
			box-shadow 0 0 0 12px dark
			.title
				display none

.dark-back
	background-color alpha(#000, 75%)
.notepad
	width 100%
	height 100%
	display flex
	justify-content center
	align-items center

.patients
	position absolute
	left 0
	right 0
	bottom 0
	top 0
	display flex
	align-items flex-start
	justify-content center
	text-align center
	transition 0.25s easeOutQuart
	&.dialog-box-enter
		.content
			transform translate(-50%, -55%)
			opacity 0
	&.dialog-box-leave-to
		.content
			opacity 0
	.bg
		position absolute
		left 0
		right 0
		bottom 0
		top 0
		background-color alpha(#000, 50%)
	.content
		position absolute
		left 50%
		top 50%
		transform translate(-50%, -50%)
		background-color dark
		width 100%
		max-width 450px
		// margin-top 100px
		box-shadow 0 4px 8px alpha(dark, 50%)
		color dark
		// height calc(600px + 52px)
		height calc(100% - 200px)
		transition 0.25s easeOutQuart
		.title
			position absolute
			left 0
			top -40px
			height 40px
			padding 0 24px
			background-color dark
			border-top-right-radius 8px
			color #fff
			font-weight 700
			white-space nowrap
			line-height @height
		.pages
			margin 40px 8px 8px 8px
			height calc(100% - 52px)
			.page
				position absolute
				left 0
				top 0
				right 0
				height 100%
				background-color #fff
				box-shadow 0 0 4px 1px alpha(#000, 50%)
				transition 0.1s easeOutQuart
				&.page-patients
					box-shadow inset 0 4px 0 0 red, 0 0 4px alpha(#000, 50%)
					.tab
						left 0
						background-color red
				&.page-filters
					box-shadow inset 0 4px 0 0 yellow, 0 0 4px alpha(#000, 50%)
					.tab
						left calc(33% - 12px)
						background-color yellow
				&.page-search
					box-shadow inset 0 4px 0 0 blue, 0 0 4px alpha(#000, 50%)
					.tab
						left calc(66% - 24px)
						background-color blue
				&.active
					animation page_active 0.35s easeOutQuart forwards
				.tab
					position absolute
					top -32px
					width calc(32% - 12px)
					height 36px
					background-color #666
					border-top-left-radius 8px
					border-top-right-radius 8px
					line-height 32px
					white-space nowrap
					cursor pointer
					color #fff
					font-weight 700
					font-size 1.4rem
				.page-content
					height 100%
					width 100%
					padding 8px 8px 0 8px
					> .filter-tabs
						position absolute
						left 0px
						top 4px
						right 0px
						display flex
						align-items center
						justify-content center
						height 64px
						padding 16px 16px 20px 16px
						z-index 2
						.filter-tab
							display inline-block
							width 50%
							height 32px
							line-height @height
							box-shadow 0 0 0 2px red
							cursor pointer
							font-size 1.4rem
							background-color #fff
							// background-color lighten(red, 90%)
							&:first-child
								border-top-left-radius 16px
								border-bottom-left-radius 16px
							&:last-child
								border-top-right-radius 16px
								border-bottom-right-radius 16px
							&.active
								background-color red
								color #fff
					> .patients-scroll
						// height calc(100% - 64px)
						height 100%
						.tab-patients
							width 100%
							padding 72px 16px 0 16px
					> .loading
						absolute 0
						background alpha(#fff, 90%) url(../../assets/img/button-loader-dark.svg) center center no-repeat
						background-size 80px auto
						z-index 2
			.close
				position absolute
				right 0
				top -32px
				width (3 * 12px)
				height 32px
				background purple url(../../assets/img/close.svg) center center no-repeat
				background-size 16px auto
				border-top-left-radius 8px
				border-top-right-radius 8px
				cursor pointer

@keyframes page_active
	0%
		transform translateY(0)
	50%
		transform translateY(-64px) translateX(-32px) rotate(-4deg)
	100%
		transform translateY(4px)



@media (min-width: 450px)
	.patients
		.content
			.title
				border-top-left-radius 8px


</style>
