const capitalize = function (value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
}

const capitalizeEachWord = x => String(x)
	.toLowerCase()
	.replace(/(\b[a-z](?!\s))/g, y => y.toUpperCase())

const contentTypeLast = value => (value.match(/.*\/(.*)$/) || []).pop()

const toUpperCase = x => String(x).toUpperCase()

const removeHtmlTags = x => x.replace(/<\/?[^>]+(>|$)/g, '')

const plural = x => x.endsWith('s') ? x : `${x}s`

const singular = x => x.endsWith('s') ? x.slice(0, -1) : x

const feminize = (x, actualLanguage = null, testLanguage = 'fr', suffix = 'e') =>
	actualLanguage === testLanguage ? `${x}${suffix}` : x

module.exports = {
	filters: {
		capitalize,
		contentTypeLast,
		toUpperCase,
		removeHtmlTags,
		plural,
		singular,
		feminize,
		capitalizeEachWord
	}
}
