<template>
	<div class="tab-patients">

		<div v-if="is_accredited">
			<div v-for="game_module in accredited_modules" class="module">
				<div class="module-header">
					<div class="module-label">{{ $t('module') }}:</div>
					<div class="module-name">{{ game_module.document.title }}</div>
				</div>
				<div class="module-completed">
					<div class="text">{{ $t('completed') }} {{ game_module.games.filter(game => logged_user.achievement.gameJournal.find(g => g.gameId === game.id)).length }}/{{ totalNbGames }}</div>
					<div class="bars">
						<div v-for="game in game_module.games" :key="game.id" class="bar" :class="(logged_user.achievement.gameJournal.find(g => g.gameId === game.id) || {}).status"></div>
					</div>
				</div>
				<div v-show="expanded_module" class="module-games">
					<patient-file
						v-for="game in game_module.games"
						:key="game.id"
						:patient="game"
						:noModuleName="true"
						@information-summary="informationSummary"
					/>
				</div>
				<div class="module-button" v-hammer:tap="() => toggle_module(game_module)">
					<span v-if="expanded_module === game_module" class="more">{{ $t('show_less_more', { what: $t('less') }) }} <span class="triangle"></span></span>
					<span v-else class="less">{{ $t('show_less_more', { what: $t('more') }) }} <span class="triangle"></span></span>
				</div>
			</div>
		</div>

		<div v-else>
			<patient-file
				v-for="game in unaccredited_games"
				:key="game.id"
				:patient="game"
				@information-summary="informationSummary"
			/>
		</div>


		<div v-if="!scopes || (scopes && !scopes.includes('completed'))" class="end">
			<!-- <p class="no-more">No more patient cases</p> -->
			<ui-button color="confirm" v-hammer:tap="() => see_archives()">{{ $t('see_completed') }}</ui-button>
		</div>

		<div v-if="game.games.length === 0" class="no-games">
			<div class="icon"></div>
			<p class="title">{{ $t('no_game_found') }}</p>
			<div class="buttons">
				<ui-button color="red" v-hammer:tap="() => show_filter_page()">{{ $t('search') }}</ui-button>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import PatientFile from '@/components/patients/patient-file'

export default {
	name: 'tab-patients',
	props: [ 'scopes', 'totalNbGames' ],
	data() {
		return {
			expanded_module: null
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'patients', 'logged_user' ]),
		is_accredited() {
			return this.patients?.filters?.accredited_game_equal || false
		},
		accredited_modules() {
			if (this.is_accredited) {
				const modules = [ ...new Set(this.game.games.reduce((acc, g) => {
						acc = [ ...acc, ...g.clientModules ]
						return acc
					}, [])) ]
				modules.forEach((m) => {
					m.games = this.game.games.filter(g => g.clientModules.find(mod => mod.id === m.id))
				})
				return modules
			}
			return []
		},
		unaccredited_games() {
			if (!this.scopes) return this.game.games.filter(game => !this.logged_user.achievement.gameJournal.find(g => g.gameId === game.id))
			return this.game.games
		}
	},
	methods: {
		informationSummary(data) {
			this.$emit('information-summary', data)
		},
		show_filter_page() {
			this.$emit('set_active_tab', 'filters')
		},
		see_archives() {
			this.$router.push({ path: 'resources' })
		},
		toggle_module(game_module) {
			this.expanded_module = this.expanded_module === game_module ? null : game_module
		}
	},
	components: {
		PatientFile
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.tab-patients
	display block
	width 100%

	.module
		margin-bottom 24px
		border 2px solid #888
		.module-header
			background-color #89B6C8
			padding 8px
			border-bottom 4px solid #E1EFF5
			.module-label
				font-size 1.4rem
			.module-name
				color #fff
		.module-completed
			padding 16px 8px 8px 8px
			font-weight 700
			color #FF6133
			text-transform uppercase
			.text
				margin 0 0 8px 0
			.bars
				height 8px
				flex center center
				.bar
					height 100%
					width 100%
					margin 4px
					background-color #FDC9AB
					border-radius 3px
					&.completed
						background-color #FF6133
		.module-games
			padding 16px
			>>> .patient-file
				&:last-child
					margin 0
		.module-button
			width calc(100% - 24px)
			height 40px
			margin 12px
			flex center center
			background-color #4D94B2
			border-radius 12px
			color #fff
			text-transform uppercase
			cursor pointer
			&:hover
				background-color darken(#4D94B2, 10%)
			&:active
				background-color darken(#4D94B2, 10%)
				transform translateY(1px)
			> .more, > .less
				display block
				flex center center
				.triangle
					width 0
					height 0
					margin 0 0 0 8px
					border-style solid
					border-width 12px 8px 0 8px
					border-color #fff transparent transparent transparent
					transition transform 0.25s easeOutQuart
			> .more .triangle
				transform rotate(180deg)


	.patient-file
		margin-bottom 24px
	.end
		margin-bottom 24px
		padding-top 24px
		border-top 2px solid #ddd
		.no-more
			margin-bottom 8px
			text-transform uppercase
			color #aaa
			font-size 1.2rem
			font-weight 700
		.archives-button
			height 40px
			border-radius 4px
			line-height @height
			background-color red
	.no-games
		flex center center
		flex-direction column
		min-height 300px
		.icon
			margin 0 0 16px 0
			width 48px
			height 48px
			background url(../../assets/img/search.svg) center center no-repeat
			background-size 100% auto
			&:after
				content ''
				position absolute
				left -8px
				top 43%
				width 120%
				height 4px
				background-color gray
				border-radius 2px
				transform rotate(-45deg)
				box-shadow 0 4px 0 0 #fff, 0 -1px 0 0 #fff
		.title
			margin 0 0 16px 0
			text-transform uppercase
			font-weight 700
			font-size 2rem
			color gray

</style>
