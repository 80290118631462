<template>
	<div class="tab-filters">
		<div class="form">
			<div class="scroll" v-scrollbar:scrollArrows>
				<div>
					<div class="row">
						<label for="practice_field" class="label">{{ $t('practice_field') }} : {{ userPracticeField }}</label>
						<div class="value">
							<div class="select">
								<select
									id="practice_field"
									v-model="practiceField"
								>
									<option
										v-if="!practiceField"
										value=""
										disabled="disabled"
									>
										{{ $t('add one more')}}
									</option>
									<option
										v-for="{ id, html } in practiceFields"
										:value="id"
										:key="id"
									>
										{{ html }}
									</option>
								</select>
							</div>
						</div>
					</div>

					<div class="row">
						<label for="needEstimatedDifficulty" class="need-checkbox" :class="{ checked: neededEstimatedDifficulty }"><input type="checkbox" id="needEstimatedDifficulty" v-model="neededEstimatedDifficulty"></label>
						<label for="needEstimatedDifficulty" class="label">{{ $t('estimated_difficulty') }}</label>
						<div v-show="neededEstimatedDifficulty" class="value">
							<div class="input-range inner" id="estimated-difficulty" :disabled="neededEstimatedDifficulty === false"></div>
						</div>
					</div>

					<div class="row">
						<label for="needGender" class="need-checkbox" :class="{ checked: neededGender }"><input type="checkbox" id="needGender" v-model="neededGender"></label>
						<label for="needGender" class="label">{{ $t('gender') }}</label>
						<div v-show="neededGender" class="value">
							<div class="select">
								<select id="gender" v-model="gender" :disabled="neededGender === false">
									<option value="no-preferences" class="default">{{ $t('no_preferences') }}</option>
									<option :value="$t('female')">{{ $t('female') }}</option>
									<option :value="$t('male')">{{ $t('male') }}</option>
								</select>
							</div>
						</div>
					</div>

					<div class="row">
						<label for="needAge" class="need-checkbox" :class="{ checked: neededAge }"><input type="checkbox" id="needAge" v-model="neededAge"></label>
						<label for="needAge" class="label">{{ $t('age') }}</label>
						<div v-show="neededAge" class="value">
							<div class="input-range" id="age" :disabled="neededAge === false"></div>
						</div>
					</div>
				</div>
			</div>

			<div class="buttons">
				<div
					v-hammer:tap="() => reset()"
					class="button"
				>{{ $t('reset') }}</div>
				<div
					v-hammer:tap="() => submit()"
					class="button"
				>{{ $t('submit') }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import noUiSlider from 'nouislider'
import 'nouislider/distribute/nouislider.min.css'
import { DOMValuesWithFunctionsByIds } from '@/js/utils/utils'
import { filters } from '@/js/ui/filters'

export default {
	name: 'tab-filters',
	data() {
		return {}
	},
	filters,
	computed: {
		...mapState({
			userPracticeField: state => state.user.logged_user.profile.practiceFieldName,
			practiceFields: state => state.user.practice_fields || [],
			practice_field: state => state.patients.filters.practice_field_include,
			estimated_difficulty_equal: state => state.patients.filters.estimated_difficulty_equal,
			getGender: state => state.patients.filters.gender_equal,
			age_greater_than: state => state.patients.filters.age_greater_than,
			age_less_than: state => state.patients.filters.age_less_than,
			getBranded: state => state.patients.filters.branded,
			getAccredited: state => state.patients.filters.accredited_game_equal,
		}),
		...mapGetters([ 'app', 'game' ]),
		neededEstimatedDifficulty: {
			get() {
				return this.$store.state.patients.neededFilters['estimated_difficulty_equal']
			},
			set (value){
				this.$store.commit('SET_PATIENTS_FILTER_NEEDED', { property: 'estimated_difficulty_equal', value })
				this.setGameFilterWith()
			}
		},
		neededGender: {
			get() {
				return this.$store.state.patients.neededFilters.gender_equal
			},
			set (value){
				this.$store.commit('SET_PATIENTS_FILTER_NEEDED', { property: 'gender_equal', value })
				this.setGameFilterWith()
			}
		},
		neededAge: {
			get() {
				return this.$store.state.patients.neededFilters.age_greater_than
			},
			set (value){
				this.$store.commit('SET_PATIENTS_FILTER_NEEDED', { property: 'age_greater_than', value })
				this.$store.commit('SET_PATIENTS_FILTER_NEEDED', { property: 'age_less_than', value })
				this.setGameFilterWith()
			}
		},
		neededBranded: {
			get() {
				return this.$store.state.patients.neededFilters.branded
			},
			set (value){
				this.$store.commit('SET_PATIENTS_FILTER_NEEDED', { property: 'branded', value })
				this.setGameFilterWith()
			}
		},
		neededAccredited: {
			get() {
				return this.$store.state.patients.neededFilters.accredited_game_equal
			},
			set (value){
				this.$store.commit('SET_PATIENTS_FILTER_NEEDED', { property: 'accredited_game_equal', value })
				this.setGameFilterWith()
			}
		},
		gender: {
			get() {
				return this.getGender
			},
			set(value) {
				this.$store.commit('SET_PATIENTS_FILTER_PROPERTY', { property: 'gender_equal', value })
				this.setGameFilterWith()
			}
		},
		practiceField: {
			get() {
				return this['practice_field']
			},
			set(value) {
				this.$store.commit('SET_PATIENTS_FILTER_PROPERTY', { property: 'practice_field_include', value })
				this.$store.commit('SET_PATIENTS_FILTER_NEEDED', { property: 'practice_field_include', value: !!value })
				this.setGameFilterWith()
			}
		},
		branded: {
			get() {
				return this.getBranded
			},
			set(value) {
				this.$store.commit('SET_PATIENTS_FILTER_PROPERTY', { property: 'branded', value })
				this.setGameFilterWith()
			}
		},
		accredited: {
			get() {
				return this.getAccredited
			},
			set(value) {
				this.$store.commit('SET_PATIENTS_FILTER_PROPERTY', { property: 'accredited_game_equal', value })
				this.setGameFilterWith()
			}
		}
	},
	methods: {
		submit() {
			this.$emit('set_active_tab', 'patients')
		},
		setGameFilterWith() {
			this.$store.dispatch('set_game_filters_with', {
				values: [ this.$store.getters.patients.filters, this.$store.getters.patients.search ], needed: [ this.$store.getters.patients.neededFilters, this.$store.getters.patients.neededSearch ]
			})
		},
		setChecked(property) {
			return { checked: this[property] }
		},
		set_check(property) {
			this[property] = !this[property]
		},
		reset() {
			this.$store.commit('RESET_PATIENTS_FILTER')
			this.setGameFilterWith()
			const setSliderValue = (min, max = min) => x => {
				if (x && x.noUiSlider) x.noUiSlider.set([min, max])
			}
			const values = DOMValuesWithFunctionsByIds({
				estimated_difficulty_equal: setSliderValue(1),
				age: setSliderValue(...[ this.age_greater_than, this.age_less_than ]),
			})
		}
	},
	async mounted() {
		await this.$store.dispatch('get_practice_fields')
		let difficulty_slider = this.$el.querySelector('#estimated-difficulty')
		noUiSlider.create(difficulty_slider, {
			start: this['estimated_difficulty_equal'],
			connect: true,
			step: 1,
			tooltips: [true],
			range: { min: 1, max: 5 },
			format: {
				to: function (value) { return value },
				from: function (value) { return value }
			}
		})
		let age_slider = this.$el.querySelector('#age')
		noUiSlider.create(age_slider, {
			start: [ this.age_greater_than, this.age_less_than ],
			connect: true,
			step: 1,
			tooltips: [true, true],
			range: { min: [0], max: [100] },
			format: {
				to: function (value) { return value },
				from: function (value) { return value }
			}
		})
		difficulty_slider.noUiSlider.on('update', (values, handle) => {
			const { 'estimated_difficulty_equal': estimatedDifficulty } = this
			if (estimatedDifficulty === values[0]) return
			this.$store.commit('SET_PATIENTS_FILTER_PROPERTY', { property: 'estimated_difficulty_equal', value: values[0] })
			this.setGameFilterWith()
		})
		age_slider.noUiSlider.on('update', (values, handle) => {
			const { age_greater_than, age_less_than } = this
			const age = [ age_greater_than, age_less_than ]
			if (JSON.stringify(age) === JSON.stringify(values)) return
			this.$store.commit('SET_PATIENTS_FILTER_PROPERTY', { property: 'age_greater_than', value: values[0] })
			this.$store.commit('SET_PATIENTS_FILTER_PROPERTY', { property: 'age_less_than', value: values[1] })
			this.setGameFilterWith()
		})
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.tab-filters
	display block
	// width 100%
	padding 16px
	position absolute
	left 0
	top 0
	right 0
	text-align left
	height 100%
	.form
		height 100%
		.scroll
			height calc(100% - 64px)
			.row
				margin 0 0 16px 0
				> .need-checkbox
					float left
					height 24px
					width 24px
					margin 4px 8px 4px 0
					box-shadow inset 0 0 0 2px dark
					cursor pointer
					&.checked:after
						content ''
						position absolute
						left 1px
						bottom 0
						width 30px
						height 38px
						background url(../../assets/img/check.svg) bottom left no-repeat
						background-size 100% auto
					input
						opacity 0
						cursor pointer
				> .label
					display inline-block
					height 32px
					margin 0 0 2px 0
					line-height @height
					white-space nowrap
					font-weight 700
					cursor pointer
					&.left
						float left
						margin 0 16px 0 0
				> .value
					display block
					background-color #f5f5f5
					padding 16px
					border-radius 4px
					>>> .input-range
						height 4px
						border none
						background-color #AEAEAE
						box-shadow none
						margin 16px 0
						&.inner
							.noUi-handle
								width 24px
								height 24px
								right -12px
								top -9px
							.noUi-tooltip
								top 0
								line-height 24px
								color #fff
						.noUi-connect
							background-color yellow
						.noUi-handle
							border none
							border-radius 50%
							box-shadow none
							background-color red
							width 16px
							height 16px
							top -6px
							right -8px
							outline none
							&:before, &:after
								display none
							.noUi-touch-area
								position absolute
								left -50%
								top -50%
								width 200%
								height 200%
						.noUi-tooltip
							bottom auto
							top -120%
							border none
							padding 0
							background transparent
					.select
						width 100%
						border-bottom 2px solid yellow
						font inherit
						color inherit
						&:before
							content ''
							position absolute
							right 8px
							top calc(50% - 4px)
							width 0
							height 0
							border-style solid
							border-width 8px 6px 0 6px
							border-color dark transparent transparent transparent

						select
							display block
							width 100%
							height 32px
							border none
							appearance none
							background transparent
							font inherit
							color inherit
							border-radius 0
							outline none
				.checkbox
					display inline-block
					margin 4px 0 0 0
					width 24px
					height 24px
					border 2px solid dark
					cursor pointer
					&.checked
						&:after
							content ''
							position absolute
							left 1px
							bottom 0
							width 30px
							height 38px
							background url(../../assets/img/check.svg) bottom left no-repeat
							background-size 100% auto
		.buttons
			position absolute
			left -24px
			bottom -24px
			right -24px
			padding 16px
			flex center center
			background-color dark
			.button
				display inline-block
				margin 0 8px
				height 48px
				padding 0 24px
				border-radius 12px
				background-color #61789E
				cursor pointer
				font inherit
				line-height @height
				color #fff
				font-weight 700
	.unavailable
		position absolute
		left 0
		top 4px
		right 0
		bottom 0
		background-color alpha(#fff, 50%)
		z-index 2
		> div
			position absolute
			left 50%
			top 50%
			background-color alpha(#f44336, 100%)
			color #fff
			padding 8px 16px
			transform rotate(-3deg) translate(-50%, -50%)
			backface-visibility hidden
			white-space nowrap
			&:before
				content ''
				position absolute
				left -2px
				top -2px
				right -2px
				bottom -2px
				box-shadow 0 0 0 4px red
</style>
