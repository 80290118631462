<template>
	<div class="tab-search">
		<form @submit.prevent ref="form" class="form">
			<div class="row">
				<label class="label">{{ $t('module_name') }}</label>
				<div class="value">
					<input type="text" class="input" v-model="moduleName">
				</div>
			</div>

			<div class="row">
				<label class="label">{{ $t('scenario_name') }}</label>
				<div class="value">
					<input type="text" class="input" v-model="scenarioName">
				</div>
			</div>

			<div class="row">
				<label class="label">{{ $t('patient_name') }}</label>
				<div class="value">
					<input type="text" class="input" v-model="patientName">
				</div>
			</div>

			<div class="row">
				<label class="label">{{ $t('certificate_id') }}</label>
				<div class="value">
					<input type="text" class="input" v-model="certificateId">
				</div>
			</div>

			<div class="buttons">
				<div class="button" v-hammer:tap="() => reset()">{{ $t('reset') }}</div>
				<div class="button" v-hammer:tap="() => submit()">{{ $t('submit') }}</div>
			</div>
		</form>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'tab-search',
	data() {
		return {
		}
	},
	computed: {
		...mapGetters([ 'app', 'game' ]),
		moduleName: {
			get() {
				return this.$store.state.patients.search['module_name_include']
			},
			set (value) {
				this.$store.commit('SET_PATIENTS_SEARCH_PROPERTY', { property: 'module_name_include', value })
				this.$store.dispatch('set_patients_search_needed', { property: 'module_name_include', value })
				this.setGameFilterWith()
			}
		},
		scenarioName: {
			get() {
				return this.$store.state.patients.search['scenario_name_include']
			},
			set (value) {
				this.$store.commit('SET_PATIENTS_SEARCH_PROPERTY', { property: 'scenario_name_include', value })
				this.$store.dispatch('set_patients_search_needed', { property: 'scenario_name_include', value })
				this.setGameFilterWith()
			}
		},
		patientName: {
			get() {
				return this.$store.state.patients.search['patient_name_include']
			},
			set (value) {
				this.$store.commit('SET_PATIENTS_SEARCH_PROPERTY', { property: 'patient_name_include', value })
				this.$store.dispatch('set_patients_search_needed', { property: 'patient_name_include', value })
				this.setGameFilterWith()
			}
		},
		certificateId: {
			get() {
				return this.$store.state.patients.search['certificate_id_equal']
			},
			set (value) {
				this.$store.commit('SET_PATIENTS_SEARCH_PROPERTY', { property: 'certificate_id_equal', value })
				this.$store.dispatch('set_patients_search_needed', { property: 'certificate_id_equal', value })
				this.setGameFilterWith()
			}
		},
		brandName: {
			get() {
				return this.$store.state.patients.search['brand_name_equal']
			},
			set (value) {
				this.$store.commit('SET_PATIENTS_SEARCH_PROPERTY', { property: 'brand_name_equal', value })
				this.$store.dispatch('set_patients_search_needed', { property: 'brand_name_equal', value })
				this.setGameFilterWith()
			}
		},
	},
	methods: {
		submit() {
			this.$emit('set_active_tab', 'patients')
},
		reset() {
			this.$store.commit('RESET_PATIENTS_SEARCH')
			this.setGameFilterWith()
		},
		setGameFilterWith() {
			this.$store.dispatch('set_game_filters_with', {
				values: [ this.$store.getters.patients.filters, this.$store.getters.patients.search ], needed: [ this.$store.getters.patients.neededFilters, this.$store.getters.patients.neededSearch ]
			})
		},
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.tab-search
	display block
	// width 100%
	padding 16px
	position absolute
	left 0
	top 0
	right 0
	text-align left
	height 100%
	.form
		height 100%
		.row
			margin 0 0 8px 0
			.label
				display block
				height 28px
				line-height @height
				white-space nowrap
				font-weight 700
				&.left
					float left
					margin 0 16px 0 0
			.value
				padding 0 8px
				.input
					display block
					width 100%
					height 32px
					padding 0 8px
					border 2px solid yellow
					appearance none
					background transparent
					font inherit
					color inherit
					border-radius 0
					outline none
					&:focus
						border-color darken(yellow, 30%)
		.buttons
			position absolute
			left -24px
			bottom -24px
			right -24px
			padding 16px
			flex center center
			background-color dark
			.button
				display inline-block
				margin 0 8px
				height 48px
				padding 0 24px
				border-radius 12px
				background-color #61789E
				cursor pointer
				font inherit
				line-height @height
				color #fff
				font-weight 700
	.unavailable
		position absolute
		left 0
		top 4px
		right 0
		bottom 0
		background-color alpha(#fff, 50%)
		z-index 2
		> div
			position absolute
			left 50%
			top 50%
			background-color alpha(#f44336, 100%)
			color #fff
			padding 8px 16px
			transform rotate(-3deg) translate(-50%, -50%)
			backface-visibility hidden
			white-space nowrap
			&:before
				content ''
				position absolute
				left -2px
				top -2px
				right -2px
				bottom -2px
				box-shadow 0 0 0 4px red

</style>
