<template>
	<div class="notepad" ref="notepad" :class="{ visible: modal_visible }" :style="{ width: modal_width + 'px', height: modal_height + 'px' }">
		<div class="modal">
			<div class="pin" :class="{ up: changing_page }" :style="{ zIndex: Math.max(...orders) + 1 }"></div>
			<div class="pages"><!-- v-hammer:swipe.horizontal="($event)=> slide($event)" -->
				<div v-for="(page, index) in pages" class="page" :key="index" :class="{ active: current_page == page }" :style="{ zIndex: orders[index] }">
					<div class="title">
						<div v-if="page.title" v-html="page.title"></div>
					</div>
					<div class="scroll" v-scrollbar:scrollArrows>
						<div>
							<div class="text">
								<ui-text-content :html="page.text" />
							</div>
						</div>
					</div>

					<div v-if="type === 'buttons'" class="footer">
						<div class="buttons">
							<ui-button
								v-if="endAction === 'close'"
								v-hammer:tap="() => close()" :loading="app.loading" color="red"
							>
								{{ $t('close') }}
							</ui-button>
							<ui-button
								v-else
								v-hammer:tap="() => isAllPagesVisited() ? complete_step() : go_to_next_page()" :loading="app.loading" color="red"
							>
								{{ isAllPagesVisited() ? $t('continue') : $t('next_page') }}
							</ui-button>
						</div>
					</div>
					<div v-if="type === 'signature'" class="footer">
						<div v-if="isAllPagesVisited()" class="footer-content">
							<div class="label" v-html="$t('stamp_for_approval')"></div>
							<div class="signature" :class="signature()" v-hammer:tap="() => isAllPagesVisited() ? approve() : null">
								<transition name="stamp">
									<div v-show="approved" class="stamp" :class="stamp()"></div>
								</transition>
							</div>
						</div>
						<ui-button v-else v-hammer:tap="()=> go_to_next_page()" color="red">{{ $t('next_page') }}</ui-button>
					</div>

					<div class="tab"></div>
				</div>
			</div>
			<div v-if="pageIndex > 0" class="arrow prev" v-hammer:tap="() => go_to_prev_page()"></div>
			<div v-if="pageIndex < pages.length - 1" class="arrow next" v-hammer:tap="() => go_to_next_page()"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiTextContent from '@/components/ui/ui-text-content'
import { LANGUAGE } from '@/js/factory/language'
import { getLocalMedia, recomposeStackHtml } from '../../js/utils/recompose-stack-html'
import { medias } from '../../store/medias'
import {manageEventListeners} from "@/js/managers/manageEventListeners"

export default {
	name: 'notepad',
	data() {
		return {
			modal_width: 361,
			modal_height: 538,
			modal_visible: false,
			approved: false,
			current_page: false,
			changing_page: false,
			orders: [],
			pageIndex: 0,
			visitedPages: [],
			manageEventListeners: manageEventListeners()
		}
	},
	props: {
		endAction: { default: null },
		pages: { default: Array },
		type: { default: 'buttons' }, // buttons / signature
	},
	computed: {
		...mapGetters([ 'app', 'game' ])
	},
	methods: {
		signature() {
			return {
				approved: this.approved,
				'signature-clickable': this.isAllPagesVisited(),
			}
		},
		stamp() {
			return [ LANGUAGE.getData() ]
		},
		approve() {
			this.approved = true
			setTimeout(() => {
				this.$store.dispatch('complete_current_step')
			}, 750)
		},
		slide(e) {
			if (e.isFinal) {
				if (e.direction === 2) this.go_to_next_page()
				else if (e.direction === 4) this.go_to_prev_page()
			}
		},
		complete_step() {
			this.$store.dispatch('complete_current_step')
		},
		close() {
			this.$emit('information-summary', null)
		},
		restart() {
			this.$store.dispatch('launch_game', { game: this.game.current.game, prevent_init: true })
		},
		go_home() {
			if (this.game.current.score) {
				this.$store.dispatch('set_end_demo', { end_demo: true })
			} else
				this.$store.dispatch('init')
		},
		isAllPagesVisited() {
			return this.visitedPages.every(x => x)
		},
		select_page(page, index) {
			this.pageIndex = index
			this.visitedPages[index] = true
			if (!this.changing_page) {
				this.current_page = page
				this.changing_page = true
				setTimeout(() => {
					let max = Math.max(...this.orders)
					this.$set(this.orders, index, max + 1)
				}, 50)
				setTimeout(() => {
					this.changing_page = false
				}, 350)
			}
		},
		go_to_prev_page() {
			let index = this.pages.findIndex((c) => { return c === this.current_page })
			this.pageIndex = index
			if (index === -1) index = 0
			index -= 1
			if (index <= -1) index = this.pages.length - 1
			let page = this.pages[index]
			this.select_page(page, index)
		},
		go_to_next_page() {
			let index = this.pages.findIndex((c) => { return c === this.current_page })
			this.pageIndex = index
			if (index === -1) index = 0
			index += 1
			if (index >= this.pages.length) index = 0
			let page = this.pages[index]
			this.select_page(page, index)
		},
		resize() {
			this.modal_visible = false
			setTimeout(() => {
				let w = 361
				let h = 538

				let diff = 160
				if (this.app.device == 'tablet') diff = 120
				else if (this.app.device == 'mobile') diff = 80

				let maxh = window.innerHeight - diff
				let maxw = Math.round(361 * maxh / 538)

				if (maxw > window.innerWidth - 16) {
					maxw = window.innerWidth - 16
					maxh = Math.round(538 * maxw / 361)
				}

				this.modal_width = maxw
				this.modal_height = maxh
				this.modal_visible = true
			}, 100)
		}
	},
	mounted() {
		this.orders = this.pages
			.map((x, index) => index + 1)
			.reverse()
		this.visitedPages = new Array(this.orders.length).fill(false)
		this.visitedPages[0] = true

		this.resize()
		this.manageEventListeners.add(window, 'resize', this.resize)
	},
	components: { UiTextContent },
	beforeDestroy() {
		this.manageEventListeners.destroy()
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.perf-normal, #app.perf-high
	.notepad-enter-active, .notepad-leave-active
		transition transform 0.25s easeOutQuart, opacity 0.25s easeOutQuart
	.notepad-enter, .notepad-leave-to
		transform translateY(-100px)
		opacity 0


#app.mobile
	.notepad .modal
		.modal-content .footer .label
			text-align left
		.pages .page .tab
			display none
		.arrow
			width 40px
			height 40px
			z-index 20000
			background-size 24px 24px
			&.prev
				left -19px
			&.next
				right -19px
		.dots
			opacity 1

.notepad
	width calc(100% - 32px)
	background url(../../assets/img/intro/bg.svg) top center no-repeat
	background-size 100% 100%
	width 361px
	height 538px
	opacity 0
	&.visible
		opacity 1
	.bg
		position absolute
		left 0
		top 50%
		width 100%
		transform translateY(-50%)
		height auto
	.modal
		position absolute
		width 93%
		height 92%
		top 6%
		left 50%
		transform translateX(-50%)
		background-color #fff
		.pin
			position absolute
			left 50%
			transform translateX(-50%)
			top -4%
			height 8%
			width 40%
			background url(../../assets/img/intro/pin.svg) center center no-repeat
			background-size 100% auto
			transition transform 0.1s easeOutQuart
			transform-origin top left
			&.up
				transform translateX(-50%) translateY(-4px)
				// transform perspective(400px) translateX(-50%) rotateX(45deg)
				// transform translateX(-50%) rotateX(45deg)
		.pages
			width 100%
			height 100%
			counter-reset pages
			.page
				position absolute
				left 0
				top 0
				width 100%
				height 100%
				background-color #fff
				box-shadow 2px 0 4px alpha(#000, 25%)
				border-radius 2px
				for i in (1..10)
					&:nth-child({ i })
						.tab
							top (62px * i)
				&.active
					transition-delay 0
					animation page_active 0.35s easeOutQuart forwards
					.tab:before
						color dark
				.title
					height 64px
					margin 0px 32px 0 32px
					border-bottom 1px solid #ccc
					color red
					text-align center
					line-height (@height + 16px)
					white-space nowrap
					text-transform uppercase
					font-size 1.35em
				.scroll
					height calc(100% - 144px)
					.text
						padding 16px 32px 0 32px
				.footer
					height 80px
					width 100%
					flex center center
					padding 0 32px
					.footer-content
						height 100%
						width 100%
						flex center center
					.label
						width 50%
						color red
						text-transform uppercase
						text-align right
						padding-right 16px
						white-space nowrap
					.signature
						display flex
						align-items center
						justify-content center
						height 56px
						width 50%
						box-shadow inset 0 0 0 2px #ccc
						&:hover
							background-color lighten(red, 90%)
						&.approved
							background-color #fff
							cursor progress
						.stamp
							display inline-block
							height 40px
							width 186px
							opacity 1
							background center center no-repeat
							background-size 100% auto
							text-align center
							font-weight 700
							font-size 1.6rem
							text-transform uppercase
							line-height @height
							cursor pointer
							color #ef3c3c
							cursor progress
							transform scale(1.1) rotate(8deg)
							&.en
								background-image url(../../assets/img/intro/stamp_en.svg)
							&.fr
								background-image url(../../assets/img/intro/stamp_fr.svg)
							&.stamp-enter-active
								transition 0.35s easeInQuart
							&.stamp-enter
								opacity 0
								transform translateY(-80px) scale(5) rotate(0)
					.buttons
						display flex
						align-items center
						justify-content center
						>>> .ui-button
							margin 0 4px
							.icon
								display block
								height 28px
								width 28px
								background center center no-repeat
								background-size 100% auto
								&.home
									background-image url(../../assets/img/home.svg)
								&.refresh
									background-image url(../../assets/img/restart.svg)
				.tab
					position absolute
					right -39px
					width 40px
					transform translateY(-32px)
					height 64px
					background-color #fff
					border-top-right-radius 8px
					border-bottom-right-radius 8px
					box-shadow 3px -1px 2px 0px alpha(#000, 50%), 3px 1px 2px 0px alpha(#000, 50%)
					cursor default
					transition 0.25s easeOutQuart
					&:before
						counter-increment pages
						content counter(pages)
						height 100%
						width 100%
						display flex
						align-items center
						justify-content center
						font-weight 700
						color #999
						font-size 1.4rem
		.arrow
			position absolute
			top calc(50% - 32px)
			height 64px
			width 64px
			border-radius 50%
			box-shadow 0 0 4px 4px alpha(#000, 25%)
			background #fff center center no-repeat
			background-size 32px auto
			cursor pointer
			&:hover
				background-color lighten(red, 90%)
			&.prev
				left -(64px + 32px)
				background-image url(../../assets/img/arrow-left.svg)
			&.next
				right -(64px + 32px)
				background-image url(../../assets/img/arrow-right.svg)

@keyframes page_active
	0%
		transform translateY(0)
	50%
		transform translateX(50%) rotate(4deg)
	100%
		transform translateY(0)

.signature-clickable
	cursor pointer

</style>
